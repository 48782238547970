import React from 'react';
import { Link } from '@reach/router';
import Layout from '../components/Layout';
import { Typography, Box, Container, Button } from '@material-ui/core';
import { useLocation } from '@reach/router';

export default function HomePage() {
    const { search } = useLocation();
    const title = 'COVID-19 Portal';
    const CLIENT_LOGO_URL = process.env.CLIENT_LOGO_URL;
    const CLIENT_LOGO_ALT = process.env.CLIENT_LOGO_ALT;
    const SWIFTFOX_LOGO_URL = process.env.SWIFTFOX_LOGO_URL;
    const SWIFTFOX_LOGO_ALT = process.env.SWIFTFOX_LOGO_ALT;

    return (
        <Layout heading={title} title={title}>
            <Container>
                <Box
                    color='text.secondary'
                    m={2}
                    style={{ textAlign: 'center' }}
                >
                    <img
                        style={{ height: '50px' }}
                        src={SWIFTFOX_LOGO_URL}
                        alt={SWIFTFOX_LOGO_ALT}
                    ></img>
                    <img
                        style={{ height: '50px', marginLeft: '15px' }}
                        src={CLIENT_LOGO_URL}
                        alt={CLIENT_LOGO_ALT}
                    ></img>
                    <br />
                    <Typography variant='h4'>
                        Welcome to the Lendlease & APC COVID-19 Portal
                    </Typography>
                    <br />
                    <Typography>
                        Please login or create an account with SwiftFox CRM and
                        complete your COVID-19 questionnaire for this work site.
                    </Typography>
                    <br />
                    <Button
                        variant='contained'
                        color='primary'
                        style={{ margin: '2em' }}
                        component={Link}
                        to={`/account${search}`}
                    >
                        Sign In
                    </Button>
                </Box>
            </Container>
        </Layout>
    );
}
